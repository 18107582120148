import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['body', 'content', 'modalContent', 'input'];

  open() {
    this.bodyTarget.classList.remove('hidden');
  }

  close() {
    this.bodyTarget.classList.add('hidden');
  }

  select(event) {
    let target = event.target;

    if (target.classList.contains('js-mobile-check-icon')) {
      return false;
    }

    if (target.nodeName === 'SPAN') {
      target = target.closest('div');
    }

    this._updateFormFieldValues(target);
  }

  _updateFormFieldValues(target) {
    let formName = this.inputTarget.name.split('[')[0];
    const formFieldName = target.dataset.filterName;
    const formFieldValue = target.dataset.filterValue;

    let formFields = document.querySelectorAll(`[name="${formName}[${formFieldName}]"]`);

    formFields.forEach(field => {
      field.value = formFieldValue;
    })

    if (target.dataset.inputsDatalistTarget || target.dataset.inputsButtonFilterTarget) {
      this._updateMobileFields(target, formFieldName, formFieldValue);
    } else {
      this._updateDesktopFields(target, formFieldName, formFieldValue);
    }
  }

  _updateMobileFields(target, formFieldName, formFieldValue) {
    let mobileFormTargets = document.querySelectorAll(`[data-inputs-mobile-datalist-target="listItem"][data-filter-name="${formFieldName}"]`);
    let mobileIcon = this._fetchMobileIcon(mobileFormTargets);

    this._updateActiveMobileItem(mobileFormTargets, formFieldValue, mobileIcon);
  }

  _fetchMobileIcon(mobileFormTargets) {
    let mobileIcon;

    mobileFormTargets.forEach(mobileTarget => {
      let icon = mobileTarget.querySelector('.js-mobile-check-icon');

      if (icon) {
        mobileIcon = icon.cloneNode(true);
        icon.remove();
      }
    })

    return mobileIcon;
  }

  _updateActiveMobileItem(mobileFormTargets, formFieldValue, mobileIcon) {
    mobileFormTargets.forEach(mobileTarget => {
      if (mobileTarget.dataset.filterValue == formFieldValue) {
        mobileTarget.classList.add('active')
        mobileTarget.closest('div').appendChild(mobileIcon);
      } else {
        mobileTarget.classList.remove('active');
      }
    })
  }

  _updateDesktopFields(target, formFieldName, formFieldValue) {
    if (this._filterIsDatalist(formFieldName)) {
      this._updateDatalist(target, formFieldName, formFieldValue);
    } else {
      this._updateIcons(target, formFieldName, formFieldValue);
    }
  }

  _filterIsDatalist(formFieldName) {
    return document.getElementById(`inputs-datalist-${formFieldName}`) !== null;
  }

  _updateDatalist(target, formFieldName, formFieldValue) {
    const desktopForm = document.getElementById(`inputs-datalist-${formFieldName}`);
    let title = desktopForm.querySelector('[data-inputs-datalist-target="title"]');
    title.innerHTML = target.dataset.value;

    let desktopFormTargets = document.querySelectorAll(`[data-inputs-datalist-target="listItem"][data-filter-name="${formFieldName}"]`);

    let firstItemSelected = this._firstItemIsSelected(desktopFormTargets, formFieldValue);

    this._updateActiveDesktopItem(firstItemSelected, desktopForm)
  }

  _updateIcons(target, formFieldName, formFieldValue) {
    const desktopForm = document.getElementById(`inputs-button-filter-${formFieldName}`);

    let buttonTargets = desktopForm.querySelectorAll('[data-inputs-button-filter-target="button"]');

    buttonTargets.forEach(button => {
      if (button.dataset.filterValue === formFieldValue) {
        button.classList.remove('primary-stroke-gray-800', 'bg-gray-100')
        button.classList.add('primary-stroke-primary-700', 'bg-primary-200');
      } else {
        button.classList.remove('primary-stroke-primary-700', 'bg-primary-200');
        button.classList.add('primary-stroke-gray-800', 'bg-gray-100')
      }
    })
  }

  _firstItemIsSelected(desktopFormTargets, formFieldValue) {
    let firstItemSelected = false;

    desktopFormTargets.forEach((desktopTarget, index) => {
      if (index === 0 && desktopTarget.dataset.filterValue === formFieldValue) {
        firstItemSelected = true;
      }
    })

    return firstItemSelected;
  }

  _updateActiveDesktopItem(firstItemSelected, desktopForm) {
    let fieldTarget = desktopForm.querySelector('[data-inputs-datalist-target="field"]');

    if (firstItemSelected) {
      fieldTarget.classList.remove('bg-primary-100');
      fieldTarget.classList.add('bg-white');
    } else {
      fieldTarget.classList.remove('bg-white');
      fieldTarget.classList.add('bg-primary-100');
    }
  }
}
