import { Controller } from "stimulus";

var _ = require("lodash");

export default class extends Controller {
  static targets = ["header"];

  connect() {
    this.configureStaticOffsets();
    this.configureDynamicOffsets();

    this.onResize = this.debounceFunc();

    this.listenToEvents();
  }

  disconnect() {
    window.removeEventListener('resize', this.onResize);
  }

  configureStaticOffsets() {
    const stickyAccordions = document.querySelectorAll("[data-controller='sticky-header']")
    const stickyAccordionsArray = Array.from(stickyAccordions);

    this.topIndex = stickyAccordionsArray.findIndex((element) => element.id == this.headerTarget.id);
    this.bottomIndex = stickyAccordionsArray.length - (this.topIndex + 1);

    this.topNavbarOffset = document.getElementById("js-header").offsetHeight;
    this.programBannersOffset = document.getElementById("js-program-banners").offsetHeight;

    this.stickyCounterweight = this.headerTarget.nextElementSibling;
  }

  configureDynamicOffsets() {
    this.bottomNavbarOffset = document.getElementsByClassName("toolbar")[0].offsetHeight;
    this.stickyHeaderHeight = (window.innerWidth < 640 ) ? 32 : 40;

    this.stickyTopOffset = this.topNavbarOffset + this.programBannersOffset + (this.topIndex * this.stickyHeaderHeight);
    this.stickyBottomOffset = this.bottomNavbarOffset + (this.bottomIndex * this.stickyHeaderHeight);
  }

  listenToEvents() {
    window.addEventListener('resize', this.onResize);

    this.observeIntersections();
  }

  debounceFunc() {
   return _.debounce(this.reloadConfigurations.bind(this), 100);
  }

  reloadConfigurations() {
    this.configureDynamicOffsets();

    this.observer.unobserve(this.triggerTarget);

    let options = { rootMargin: `-${this.stickyTopOffset}px 0px -${this.stickyBottomOffset}px 0px` };
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), options);
    this.observer.observe(this.triggerTarget);
  }

  observeIntersections() {
    let options = { rootMargin: `-${this.stickyTopOffset}px 0px -${this.stickyBottomOffset}px 0px` };
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), options);

    this.triggerTarget = this.headerTarget.previousElementSibling;

    this.observer.observe(this.triggerTarget);
  }

  handleIntersect(entries, observer) {
    if (entries[0].isIntersecting) {
      this.removeStickyStatus();
    } else if (this.triggerTarget.getBoundingClientRect().top < this.stickyTopOffset) {
      // over viewport
      this.addStickyTopStatus();
    } else {
      // below viewport
      this.addStickyBottomStatus();
    }
  }

  removeStickyStatus() {
    this.headerTarget.classList.add("fixed-header");
    this.headerTarget.classList.remove("sticky-header", "-top", "-bottom");
    this.headerTarget.style.top = "auto";
    this.headerTarget.style.bottom = "auto";
    this.headerTarget.style.zIndex = 0;

    this.stickyCounterweight.classList.remove("-active");
  }

  addStickyTopStatus() {
    this.headerTarget.classList.add("sticky-header", "-top", "-animated");
    this.headerTarget.classList.remove("fixed-header");

    this.headerTarget.style.top = `${this.stickyTopOffset}px`;
    this.headerTarget.style.zIndex = 20 + this.topIndex;

    this.stickyCounterweight.classList.add("-active");
  }

  addStickyBottomStatus() {
    this.headerTarget.classList.add("sticky-header", "-bottom", "-animated");
    this.headerTarget.classList.remove("fixed-header");

    this.headerTarget.style.bottom = `${this.stickyBottomOffset}px`;
    this.headerTarget.style.zIndex = 20 + this.bottomIndex;

    this.stickyCounterweight.classList.add("-active");
  }

  scrollTo() {
    const stickyTopOffset = this.topNavbarOffset + this.programBannersOffset + (this.topIndex * this.stickyHeaderHeight);
    const y = this.triggerTarget.getBoundingClientRect().top + window.pageYOffset - stickyTopOffset;

    window.scrollTo({
      top: y,
      behavior: this.isTestEnvironment() ? "auto" : "smooth"
    });
  }

  isTestEnvironment() {
    return window.location.hostname === "127.0.0.1";
  }
}
