import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["saml", "nonSaml", "moreOptions"]

  switchSignInMethods(event) {
    event.preventDefault();

    this.samlTargets.forEach((samlEl) => samlEl.classList.toggle('js-hidden'));
    this.nonSamlTarget.classList.toggle('js-hidden');
  }

  connect(event) {
    let currentAuthMethod = window.sessionStorage.getItem(this._storageKey());

    if (currentAuthMethod == this._authMethods().saml) {
      this._showSAML();
    } else if (currentAuthMethod == this._authMethods().nonSaml) {
      this._showNonSaml();
    }
  }

  showSAMLHandler(event) {
    event.preventDefault();
    this._showSAML();
  }

  showNonSamlHandler(event) {
    event.preventDefault();
    this._showNonSaml();
  }

  _showSAML() {
    if (!this.hasSamlTarget) return;

    this.samlTargets.forEach((samlEl) => samlEl.classList.remove('js-hidden'));
    this.nonSamlTarget.classList.add('js-hidden');
    if (this.hasMoreOptionsTarget) this.moreOptionsTarget.classList.remove('js-hidden');
    window.sessionStorage.setItem(this._storageKey(), this._authMethods().saml);
  }

  _showNonSaml() {
    if (!this.hasNonSamlTarget) return;

    this.samlTargets.forEach((samlEl) => samlEl.classList.add('js-hidden'));
    this.nonSamlTarget.classList.remove('js-hidden');
    if (this.hasMoreOptionsTarget) this.moreOptionsTarget.classList.add('js-hidden');
    window.sessionStorage.setItem(this._storageKey(), this._authMethods().nonSaml);
  }

  _storageKey() {
    return 'promote.login.authMethod';
  }

  _authMethods() {
    return {
      saml: 'saml',
      nonSaml: 'nonSaml'
    };
  }
}
