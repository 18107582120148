import { Controller } from "stimulus";

let debounce = require('lodash/debounce');

export default class extends Controller {
  static targets = ['container', 'header', 'contentRow'];
  connect() {
    this._setScrollOnOverflow();

    this.scrollHandler = this._onScroll.bind(this);

    this.headerTarget.addEventListener('scroll', this.scrollHandler, false);
  }

  disconnect() {
    this.headerTarget.removeEventListener('scroll', this.scrollHandler.bind(this));
  }

  _onScroll() {
    this.contentRowTargets.forEach(row => {
      row.scrollLeft = this.headerTarget.scrollLeft;
    })
  }

  _setScrollOnOverflow() {
    const containerWidth = this.containerTarget.getBoundingClientRect().width;

    if ((this._maxRowWidth() > containerWidth) || (this._headerWidth() > containerWidth)) {
      this.headerTarget.style.overflowX = 'scroll';
    }
  }

  _maxRowWidth() {
    let widths = [];

    this.contentRowTargets.forEach((target) => {
      let sum = 0;

      target.querySelectorAll('div').forEach(cell => {
        sum = sum + cell.getBoundingClientRect().width;
      });

      widths.push(sum);
    })

    return Math.max(...widths);
  }

  _headerWidth() {
    let sum = 0;

    this.headerTarget.querySelectorAll('div').forEach(cell => {
      sum = sum + cell.getBoundingClientRect().width;
    });

    return sum;
  }
}
