import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.element.addEventListener('click', this.preventAction.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('click', this.preventAction.bind(this));
  }

  preventAction(e) {
    if (this.isElDisallowed(e.target)) {
      this.formResponseController.showPreviewError(e);
    }
  }

  isElDisallowed(target) {
    if(target.classList.contains('js-allow-preview')) return false;

    if(target.nodeName == 'BUTTON') return true;

    if(target.nodeName == 'INPUT' && target.type == 'submit') return true;

    if(target.nodeName == 'SPAN' &&
      (target.parentNode.nodeName == 'BUTTON' ||
        target.parentNode.parentNode.nodeName == 'BUTTON')) return true;

    return false;
  }

  get formResponseController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "form-response")
  }

}
